const Toast = ({ text }) => {
    return (
        <div className="fixed inset-x-0 bottom-0 z-50 flex items-center justify-center p-4">
            <div className="flex items-center gap-2 h-10 px-4 bg-brand rounded shadow text-black animate-popup">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 fill-black">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                </svg>
                <p className="text-sm font-bold">{text}</p>
            </div>
        </div>
    );
};

export default Toast;
