import PropTypes from "prop-types";

const ExternalLink = ({ href, className, children, onClick }) => {
    return (
        <a href={href} className={className} target="_blank" rel="noopener noreferrer" onClick={onClick}>{children}</a>
    );
};

ExternalLink.propTypes = {
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func
};

export default ExternalLink;
