import { createContext, useContext, useEffect, useState } from "react";

import Toast from "../components/toast";

const UIStateContext = createContext();

const UIStateProvider = ({ children }) => {
    const [toast, setToast] = useState(null);
    useEffect(() => {
        let timeout;
        if (toast) {
            timeout = setTimeout(() => {
                setToast(null);
            }, 2000);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [toast]);
    const value = {
        setToast
    };
    return (
        <UIStateContext.Provider value={value}>
            {children}
            {toast && (
                <Toast text={toast} />
            )}
        </UIStateContext.Provider>
    );
};

const useUIState = () => useContext(UIStateContext);

export {
    UIStateProvider,
    useUIState
};
