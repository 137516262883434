const Header = () => {
    return (
        <header className="bg-gradient-main">
            <div className="flex justify-between max-w-2xl mx-auto p-4">
                <a href="https://www.stancebeam.com" className="">
                    <img src="/static/images/logo-light.svg" alt="StanceBeam Logo" className="h-12" />
                </a>
                <nav className="flex items-center gap-8">
                    <a href="https://www.stancebeam.com">Home</a>
                    <a href="https://www.stancebeam.com/store">Store</a>
                </nav>
            </div>
        </header>
    );
};

export default Header;
