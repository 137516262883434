import { AppError, isDevelopment } from "../utils";

let baseURL = "https://sbguestapi.azurewebsites.net/api";

if (isDevelopment) {
    baseURL = "http://localhost:7071/api";
}

const getHighlightsVideo = async (id) => {
    const url = `${baseURL}/hl.video.get/${id}`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        if (response.status === 404) {
            const { message } = await response.json();
            throw new AppError(message);
        }
        throw new Error();
    } catch (error) {
        let message = "Something went wrong!";
        if (error instanceof AppError) {
            message = error.message;
        }
        throw new Error(message);
    }
};

const createLog = async (data) => {
    const url = `${baseURL}/hl.video.log/${data.highlightsId}`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        if (response.status === 404) {
            const { message } = await response.json();
            throw new AppError(message);
        }
        throw new Error();
    } catch (error) {
        let message = "Something went wrong!";
        if (error instanceof AppError) {
            message = error.message;
        }
        throw new Error(message);
    }
};

export {
    getHighlightsVideo,
    createLog
};
