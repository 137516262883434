import { Routes, Route } from "react-router-dom";

import Header from "./components/header";
import Highlights from "./pages/highlights";
import NotFound from "./components/not-found";

const App = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <Routes>
                <Route path="/:id" element={<Highlights />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
};

export default App;
