import { useEffect, useRef } from "react";
import { browserName, fullBrowserVersion, isMobile } from "react-device-detect";

import { useUIState } from "../state/ui-state";
import { getBrowserId, isDevelopment } from "../utils";
import { createLog } from "../api";
import ExternalLink from "./external-link";

const socialPlatforms = [
    {
        id: "facebook",
        name: "Facebook",
        icon: "/static/images/facebook.svg",
        url: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
    },
    {
        id: "twitter",
        name: "twitter",
        icon: "/static/images/twitter.svg",
        url: `https://twitter.com/intent/tweet?text=${window.location.href}`
    },
    {
        id: "whatsapp",
        name: "WhatsApp",
        icon: "/static/images/whatsapp.svg",
        url: `https://api.whatsapp.com/send?text=${window.location.href}`
    }
];

const browserInfo = `${browserName}${isMobile ? " Mobile " : " "}${fullBrowserVersion}`;

const browserId = getBrowserId();

const HighlightsVideo = ({ highlightsId, video }) => {
    const videoRef = useRef();
    const dataRef = useRef({
        highlightsId,
        uid: video.uid,
        browserId,
        browserInfo
    });
    useEffect(() => {
        const beforeunload = (event) => {
            event.returnValue = "Are you sure that you want to exit?";
        };
        window.addEventListener("beforeunload", beforeunload);
        videoRef.current.focus();
        return () => {
            window.removeEventListener("beforeunload", beforeunload);
        };
    }, []);
    useEffect(() => {
        const addLog = async (logType) => {
            if (isDevelopment) {
                return;
            }
            try {
                await createLog({
                    ...dataRef.current,
                    logType
                });
            } catch (error) {
                console.log(error.message);
            }
        };
        navigator.geolocation.getCurrentPosition((position) => {
            const { longitude, latitude } = position.coords;
            dataRef.current.geolocation = {
                longitude,
                latitude
            };
            addLog("linkOpened");
        }, (error) => {
            console.error(error.message);
            addLog("linkOpened");
        });
        const element = videoRef.current;
        const playListener = (event) => addLog("videoPlaying");
        const endedListener = (event) => addLog("videoEnded");
        element.addEventListener("play", playListener);
        element.addEventListener("ended", endedListener);
        return () => {
            element.removeEventListener("play", playListener);
            element.removeEventListener("ended", endedListener);
        };
    }, []);
    const { setToast } = useUIState();
    const onShare = (platform) => {
        const sharedOn = dataRef.current.sharedOn || [];
        sharedOn.push(platform);
        dataRef.current.sharedOn = sharedOn;
    };
    const copyLink = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            onShare("linkCopied");
            setToast("Link copied to the clipboard");
        } catch (error) {
            setToast("Unable to copy the link");
        }
    };
    return (
        <div className="flex flex-col items-center w-full max-w-2xl mx-auto p-4 bg-white">
            <p className="">Share</p>
            <div className="flex gap-2 mt-2">
                {socialPlatforms.map(({ id, name, icon, url }) => (
                    <ExternalLink
                        key={id}
                        href={url}
                        className="social-btn"
                        onClick={() => onShare(id)}>
                        <img src={icon} alt={`Share on ${name}`} />
                    </ExternalLink>
                ))}
            </div>
            <button className="btn mt-2" onClick={copyLink}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                </svg>
                <span>Copy Link</span>
            </button>
            <video
                src={video.hlUrl}
                className="aspect-[9/16] w-full max-w-xs mt-4"
                ref={videoRef}
                controls
                autoPlay
                playsInline />
        </div>
    );
};

export default HighlightsVideo;
