import { useEffect } from "react";

const NotFound = () => {
    useEffect(() => {
        window.location.href = "https://www.stancebeam.com";
    }, []);
    return (
        <div className="grow flex flex-col items-center justify-center gap-2">
            <div className="spinner"></div>
            <p className="text-lg">Not Found</p>
        </div>
    );
};

export default NotFound;
