import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getHighlightsVideo } from "../api";
import HighlightsVideo from "../components/highlights-video";

const Highlights = () => {
    const [loading, setLoading] = useState(true);
    const [video, setVideo] = useState();
    const [error, setError] = useState();
    const { id } = useParams();
    useEffect(() => {
        const getVideo = async () => {
            try {
                const data = await getHighlightsVideo(id);
                setVideo(data);
            } catch (error) {
                setError(error.message);
            }
            setLoading(false);
        };
        getVideo();
    }, [id]);
    if (loading) {
        return (
            <div className="grow flex flex-col items-center justify-center gap-2">
                <div className="spinner"></div>
                <p className="text-lg">Loading Highlights</p>
            </div>
        );
    }
    if (error) {
        return (
            <div className="grow flex flex-col items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-16 h-16 fill-red-600">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                </svg>
                <p className="text-lg text-red-600">{error}</p>
            </div>
        );
    }
    return (
        <HighlightsVideo highlightsId={id} video={video} />
    );
};

export default Highlights;
