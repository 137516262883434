class AppError extends Error {

    constructor(message) {
        super(message);
        this.message = message;
    }
}

const randomBrowserId = () => {
    let id = "browser-";
    for (let i = 0; i < 8; i++) {
        const random = Math.random() * 10;
        id += Math.floor(random);
    }
    return id;
};

const getBrowserId = () => {
    let browserId = localStorage.getItem("browserId");
    if (!browserId) {
        browserId = randomBrowserId();
        localStorage.setItem("browserId", browserId);
    }
    return browserId;
};

const isDevelopment = window.location.hostname === "localhost";

export {
    AppError,
    getBrowserId,
    isDevelopment
};
