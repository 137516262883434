import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import { UIStateProvider } from "./state/ui-state";
import App from "./app";

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
    <BrowserRouter>
        <UIStateProvider>
            <App />
        </UIStateProvider>
    </BrowserRouter>
);
